import axios from "axios"
let isDev = false
export default {
	common: {
		baseUrl: isDev ? 'http://dev.fengyisuan.com/app' : "https://fengyisuan.com/app"
	},
	request(options = {}) {
		return new Promise((resolve) => {
			axios({
				url: this.common.baseUrl + options.url,
				method: options.method,
				dataType: "json",
				data: options.data || {}
				})
				.then(function(data) {
					resolve(data.data)
				});
		})
	}
}